import {
  useContext,
  useEffect,
} from 'react'

import { withRouter } from 'react-router-dom'

import { ScrollToTopContext } from './context'

import { Props } from './types'

const ScrollToTop: React.FC<Props> = ({ location: { pathname } }): React.ReactElement | null => {
  const { isEnabled } = useContext(ScrollToTopContext)

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    window.scrollTo(0, 0)
  }, [ pathname ]) // eslint-disable-line react-hooks/exhaustive-deps

  return null // eslint-disable-line no-null/no-null
}

export * from './types'

export default withRouter(ScrollToTop)
